<template>
  <div class="mobile-navigation">
    <button class="mobile-navigation__open" @click="open = true"></button>
    <div class="mobile-navigation__modal" v-if="open">
      <button class="mobile-navigation__close"  @click="open = false"></button>
      <ul>
        <li class="mobile-navigation__navigation-item"><a href="/">Etusivu</a></li>
        <li class="mobile-navigation__navigation-item"><a href="/keikkapaikat">Keikkapaikat</a></li>
        <li class="mobile-navigation__navigation-item"><a href="/kaupungit">Kaupungit</a></li>
        <li v-if="isLoggedIn" class="mobile-navigation__navigation-item"><a href="/users/edit">Profiili</a></li>
        <li v-if="isLoggedIn" class="mobile-navigation__navigation-item"><a href="/users/sign_out" data-method="delete">Kirjaudu ulos</a></li>
        <li v-if="false" class="mobile-navigation__navigation-item"><a href="/users/sign_in">Kirjaudu sisään</a></li>
        <li v-if="false" class="mobile-navigation__navigation-item"><a href="/users/sign_up">Rekisteröidy</a></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  data: function () {
    return {
      open: false
    }
  },
  methods: {

  },
  props: ['isLoggedIn']
}
</script>
