<template>
  <div class="comments">
    <div class="comments__new" v-if="currentUser">
        <img class="comments__new__profile-image" :src="currentUser.profile_image" />
        <div class="comments__new__message-container">
          <textarea class="comments__new__message" rows="5" placeholder="Kirjota kommentti" v-model="comment"></textarea>
          <button @click="addComment" class="comments__new__button">Kommentoi</button>
        </div>
    </div>
    <div class="comment" v-for="comment in comments" :key="comment.id">
      <img class="comment__profile-image" :src="comment.user.profile_image" />
      <div>
        <h3 class="comment__name-and-date">{{comment.user.first_name}} | {{comment.created_at | formatGigDate}}</h3>
        <div class="comment__message">{{comment.message}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  data: function () {
    return {
      comment: "",
      comments: []
    }
  },
  created: function() {
    this.comments = this.defaultComments;
  },
  methods: {
    addComment: function() {
      const self = this;
      axios.post('/comment',  {
        id: self.resourceId,
        resource: self.resource,
        comment: self.comment
      })
      .then((response) => {
        this.comments = response.data.comments;
        this.comment = ""
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  },
  props: ['defaultComments', 'currentUser', 'resource', 'resourceId']
}
</script>
