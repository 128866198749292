<template>
  <div class="like-button">
     <button class="like-button__button" v-bind:class="{ 'like-button__button--liked': liked }" v-on:click="toggleLike()"></button>
  </div>
</template>

<script>
import axios from 'axios';
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  data: function () {
    return {
    }
  },
  computed: {
    totalLikes: function() {
      return this.$store.state.venueLikes
    },
    liked: function() {
      return this.$store.state.liked;
    }
  },
  created: function () {
    this.$store.commit('setLiked', this.hasLiked);
  },
  methods: {
    toggleLike: function() {
      if (this.liked) {
        this.unlike();
      } else {
        this.like();
      }
    },

    like: function() {
      this.$store.commit('setLiked', true);
      const self = this;
      axios.post('/likes',  {
        id: self.resourceId,
        resource: self.resource
      })
      .then(function (response) {
        self.$store.commit('setLikes', response.data.likes);
        self.$store.commit('setLikeCount', response.data.totalLikes);
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    unlike: function() {
      this.$store.commit('setLiked', false);
      const self = this;
      axios.delete('/likes?id=' + self.resourceId + '&resource=' + self.resource, {
        params: { id: self.resourceId, resource: self.resource },
      })
      .then(function (response) {
        self.$store.commit('setLikes', response.data.likes);
        self.$store.commit('setLikeCount', response.data.totalLikes);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
  props: ['resourceId', 'resource', 'hasLiked']
}
</script>
