<template>
  <div v-if="open" class="cookie-modal">
    <h2>Sallitaankos keksit?</h2>
    <button v-on:click="acceptAllCookies()" class="accept-all">Salli kaikki evästeet</button>
    <button v-on:click="acceptFunctionalCookies()" class="accept-functional">Salli vain välttämättömät</button>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

export default {
  data: function () {
    return {
      open: true
    }
  },
  mounted: function () {
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    if (cookiesAccepted) this.open = false;
  },
  methods: {
    acceptAllCookies: function () {
      Cookies.set('cookiesAccepted', 'all');
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
      this.open = false;
    },
    acceptFunctionalCookies: function () {
      Cookies.set('cookiesAccepted', 'functional');
      this.open = false;
    }
  },
  props: []
}
</script>
