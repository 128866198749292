<template>
  <div class="gig-search">
    <GigSearchMobileFilters
      :selectedCity="selectedCity"
      :selectedGenre="selectedGenre"
      :defaultSelectedDate="selectedDate"
      :defaultSelectedDateTo="selectedDateTo"
      :cities="cities"
      :genres="genres"
      @filtersChanged="mobileFiltersChanged"
    />

    <div class="gig-search__filters">
      <div class="gig-search__filter-container">
        <label class="gig-search__filter-label">Kaupunki</label>
        <select class="gig-search__city-filter" v-model="selectedCity" @change="cityChanged">
          <option :value="false">Valitse</option>
          <option :value="city" v-for="city in cities" :key="city.id">{{city.name}}</option>
        </select>
      </div>

      <div class="gig-search__filter-container">
        <label class="gig-search__filter-label">Aika</label>
        <div class="gig-search__date-filter" @click="toggleDateFilter">
          <template v-if="!selectedDate">
            Valitse
          </template>

          <template v-if="isTodaySelected()">
            Tänään
          </template>

          <template v-else-if="isTomorrowSelected()">
            Huomenna
          </template>

          <template v-else-if="isWeekendSelected()">
            Viikonloppuna
          </template>

          <template v-else-if="isNextWeekSelected()">
            Ensi viikolla
          </template>

          <template v-else-if="selectedDate">
            {{selectedDate | formatDay }}
          </template>
        </div>
        <div v-if="dateFilterOpen" class="gig-search__date-filter--open">
          <div class="gig-search__predefined-dates">
            <a @click="selectToday" v-bind:class="{'active': isTodaySelected()}">Tänään</a>
            <a @click="selectTomorrow" v-bind:class="{'active': isTomorrowSelected()}">Huomenna</a>
            <a @click="selectWeekend" v-bind:class="{'active': isWeekendSelected()}">Viikonloppu</a>
            <a @click="selectNextWeek" v-bind:class="{'active': isNextWeekSelected()}">Ensi viikolla</a>
          </div>
          <datepicker 
            @selected="dateChanged($event)" 
            :disabled-dates="{to: dateYesterday()}" 
            :highlighted="{dates: [dateToday()]}" 
            placeholder="Valitse" format="dd.MM.yyyy" 
            :inline="true"
            :language="fi"
          />
        </div>
      </div>

      <div class="gig-search__filter-container" v-if="false">
        <label class="gig-search__filter-label">Genre</label>
        <select class="gig-search__genre-filter" v-model="selectedGenre" @change="filtersChanged">
          <option :value="false">Valitse</option>
          <option :value="genre" v-for="genre in genres" :key="genre.id">{{genre.name}}</option>
        </select>
      </div>
    </div>
    <div class="gigs-list gig-search__gigs-list">
      <div class="gigs-list__gigs" v-on:click="saveScrollPosition">
         <gig class="gigs-list__gig" v-for="gig in visibleGigs" :gig="gig" :key="gig.id" v-on:click="saveScrollPosition"></gig>
       </div>

       <div class="gigs-list__button-container">
          <button class="gigs-list__load-more" @click="loadMore" v-if="hasMoreGigs()">Näytä lisää keikkoja</button>
       </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import GigSearchMobileFilters from './GigSearchMobileFilters.vue';
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'
import {updateUrlParams} from '../packs/application';

const GIGS_PER_PAGE = 9;

import Gig from './Gig.vue';
import moment from 'moment';
import { fi } from 'vuejs-datepicker/dist/locale';

export default {
  data: function () {
    return {
      page: 1,
      gigs: [],
      cities: [],
      genres: [],
      selectedCity: false,
      selectedGenre: false,
      selectedDate: undefined,
      selectedDateTo: undefined,
      dateFilterOpen: false,
      fi: fi
    }
  },
  mounted: function() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    const cityId = urlParams.get('city') ?? this.defaultCity;
    const date = urlParams.get('date');
    if (page) {
      this.page = parseInt(page);
    }
    if (cityId) {
      this.selectedCity = {id: cityId};
    }
    if (date) {
      if (date === 'today') {
        this.selectedDate = this.dateToday();
      } else if (date === 'tomorrow') {
        this.selectedDate = this.dateTomorrow();
      } else if (date === 'weekend') {
        this.selectedDate = this.dateStartOfFriday();
        this.selectedDateTo = this.dateEndOfSunday();
      } else if (date === 'nextweek') {
        this.selectedDate = this.dateNextWeekMonday();
        this.selectedDateTo = this.dateNextWeekSunday();
      } else {
        const newDate = new Date(date);
        this.selectedDate = newDate;
      }
    }
    this.getGigsFromApi(cityId);
  },
  updated: function() {
    const path =  window.location.pathname;
    const scroll = localStorage.getItem(path + ':scroll');
    if (scroll && this.gigs.length > 0) {
      window.scroll({
        top: scroll
      });
      localStorage.removeItem(path + ':scroll');
    }
  },
  components: {
     Gig,
     GigSearchMobileFilters
  },
  computed: {
     visibleGigs: function() {
        const visibleGigsCount = this.page * GIGS_PER_PAGE;
        return this.gigs.slice(0, visibleGigsCount);
     }
  },
  methods: {
    saveScrollPosition: function(event) {
      localStorage.setItem(window.location.pathname + ":scroll", window.scrollY);
    },

     loadMore: function () {
        this.page += 1;
        updateUrlParams('page', this.page);
     },

     dateChanged(date) {
       this.selectedDate = date;
       this.filtersChanged();
       this.dateFilterOpen = false;
       const momentDate = moment(date);
       updateUrlParams('date', momentDate.format('YYYY-MM-DD'));
     },

     filtersChanged() {
       this.getGigsFromApi();
     },

     cityChanged() {
      this.filtersChanged();
      updateUrlParams("city", this.selectedCity.id);
     },

     dateToday() {
      const now = moment()
      return now.startOf('day').toDate();
     },

     dateTomorrow() {
       return moment().add(1, 'days').startOf('day').toDate();
     },

     dateNextWeekMonday() {
      const dayINeed = 1; // for Thursday
      const today = moment().isoWeekday();

      // if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) { 
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed).toDate();
      } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, 'weeks').isoWeekday(dayINeed).toDate();
      }
     },

     dateNextWeekSunday() {
      return moment(this.dateNextWeekMonday()).add(6,'days').toDate();
     },

     dateStartOfFriday() {
      return moment().isoWeekday(5).startOf('day').toDate();
     },

     dateEndOfSunday() {
      return moment().isoWeekday(7).endOf('day').toDate();
     },

     selectToday() {
      this.selectedDate = this.dateToday();
      this.selectedDateTo = undefined;
      this.filtersChanged();
      this.dateFilterOpen = false;
      updateUrlParams('date', 'today');
     },

     selectTomorrow() {
      this.selectedDate = this.dateTomorrow();
      this.selectedDateTo = undefined;
      this.filtersChanged();
      this.dateFilterOpen = false;
      updateUrlParams('date', 'tomorrow');
     },

     selectWeekend() {
      this.selectedDate = this.dateStartOfFriday();
      this.selectedDateTo = this.dateEndOfSunday();
      this.filtersChanged();
      this.dateFilterOpen = false;
      updateUrlParams('date', 'weekend');
     },

     selectNextWeek() {
      this.selectedDate = this.dateNextWeekMonday();
      this.selectedDateTo = this.dateNextWeekSunday();
      this.filtersChanged();
      this.dateFilterOpen = false;
      updateUrlParams('date', 'nextweek');
     },

     dateYesterday() {
       return moment().subtract(1, 'days').toDate();
     },

     isTodaySelected() {
      if (!this.selectedDate) return false;
      return moment(this.selectedDate).isSame(moment(this.dateToday()));
     },

     isTomorrowSelected() {
      return moment(this.selectedDate).isSame(moment(this.dateTomorrow()));
     },

     isWeekendSelected() {
      return moment(this.selectedDate).isSame(moment(this.dateStartOfFriday())) && 
        moment(this.selectedDateTo).isSame(moment(this.dateEndOfSunday()));
     },

     isNextWeekSelected() {
      return moment(this.selectedDate).isSame(moment(this.dateNextWeekMonday()), 'day') && 
        moment(this.selectedDateTo).isSame(moment(this.dateNextWeekSunday()), 'day');
     },

     toggleDateFilter() {
      this.dateFilterOpen = !this.dateFilterOpen;
     },

     mobileFiltersChanged(filters) {
       if (filters.cities.length > 0) {
        this.selectedCity = filters.cities[0];
       } else {
         this.selectedCity = false;
       }

       if (filters.genres.length > 0) {
        this.selectedGenre = filters.genres[0];
       } else {
        this.selectedGenre = false;
       }

      this.selectedDate = filters.selectedDate;
      this.selectedDateTo = filters.selectedDateTo;

       this.filtersChanged();
     },

     getGigsFromApi(defaultCityId) {
       const url = this.getApiUrl();
       axios.get(url)
         .then((response) => {
           this.gigs   = response.data.gigs;
           this.cities = response.data.cities;
           this.genres = response.data.genres;
           if (defaultCityId) {
            this.selectedCity = response.data.cities.find(city => city.id == defaultCityId)
           }
         })
         .catch(function (error) {
           console.log(error);
         });
     },

     hasMoreGigs: function () {
        return this.gigs.length > this.page * GIGS_PER_PAGE;
     },

     getApiUrl: function() {
       return '/gigs.json?' + this.getParams();
     },

     getParams: function() {
       let params = '';
       if (this.selectedCity) {
         params += 'city=' + this.selectedCity.id + '&';
       }

       if (this.selectedGenre) {
         params += 'genre=' + this.selectedGenre.id + '&';
       }

       if (this.selectedDate) {
         const month = parseInt(this.selectedDate.getMonth()) + 1;
         const date = this.selectedDate.getFullYear() + '-' + month + '-' + this.selectedDate.getDate();
         params += 'date=' + date + '&';
       }

       if (this.selectedDateTo) {
         const month = parseInt(this.selectedDateTo.getMonth()) + 1;
         const date = this.selectedDateTo.getFullYear() + '-' + month + '-' + this.selectedDateTo.getDate();
         params += 'dateTo=' + date;
       }

       return params;
     }
  },
  props: ['defaultCity']
}
</script>
