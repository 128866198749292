<template>
  <div>
    <div class="likes">
      <span class="likes__icon like-icon"></span>
      <span class="likes__label">Tykkäyksiä</span>
      <span class="likes__count">{{totalLikes}}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  data: function () {
    return {
      liked: this.hasLiked
    }
  },
  mounted: function () {
    const self = this;
    axios.get('/likes?id='+ this.venueId + '&resource=venue')
    .then(function (response) {
      self.$store.commit('setLikeCount', response.data.totalLikes)
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  computed: {
    totalLikes: function() {
      return this.$store.state.likeCount
    }
  },
  methods: {
  },
  props: ['venueId']
}
</script>
