<template>
    <a :href="gig.slug">
      <div class="gig__venue-logo-container" v-if=!gig.main_image_thumbnail>
        <img class="gig__venue-logo" :src="gig.venue_logo" alt=""/>
      </div>
      <img class="gig__image" :src="gig.main_image_thumbnail"  v-if=gig.main_image_thumbnail alt="" />
      <div class="gig__content">
        <h4 class="gig__time">{{gig.time | formatDay}} <span v-if="gig.time && !isMidnight(gig.time)">| {{ gig.time | formatTime }}</span></h4>
        <h3 class="gig__title">{{gig.title}}</h3>
        <h4 class="gig__venue-and-price">
          <span>{{gig.venue.short_name}}</span>
          <span v-if="gig.venue.city.name">
            , {{gig.venue.city.name}}
          </span>
          <span v-if="gig.price">
            | {{gig.price}}
          </span>
        </h4>
      </div>
    </a>
</template>

<script>
import moment from 'moment';

export default {
  data: function () {
    return {
    }
  },
  methods: {
    isMidnight: function (date) {
      const dateObject = moment(date);
      return dateObject.format('HH:mm') === '00:00';
    },
  },
  props: ['gig']
}
</script>
