<template>
   <div class="gig-search-mobile-filters">
     <div class="gig-search-mobile-filters__selected-filters" @click="openModal" role="button">
       <div class="gig-search-mobile-filters__filter-container">
         <div class="gig-search-mobile-filters__selected-filter-label">Kaupunki</div>
         <div class="gig-search-mobile-filters__selected-filter-value">{{selectedCity ? selectedCity.name : 'Ei valittu'}}</div>
       </div>
       <div class="gig-search-mobile-filters__filter-container">
         <div class="gig-search-mobile-filters__selected-filter-label">Aika</div>
         <div class="gig-search-mobile-filters__selected-filter-value">
          <template v-if="!selectedDate">
            Valitse
          </template>

          <template v-if="isTodaySelected()">
            Tänään
          </template>

          <template v-else-if="isTomorrowSelected()">
            Huomenna
          </template>

          <template v-else-if="isWeekendSelected()">
            Viikonloppuna
          </template>

          <template v-else-if="isNextWeekSelected()">
            Ensi viikolla
          </template>

          <template v-else-if="selectedDate">
            {{selectedDate | formatDay }}
          </template>
         </div>
       </div>
       <div class="gig-search-mobile-filters__filter-container" v-if="false">
         <div class="gig-search-mobile-filters__selected-filter-label">Genre</div>
         <div class="gig-search-mobile-filters__selected-filter-value">{{selectedGenre ? selectedGenre.name : 'Ei valittu'}}</div>
       </div>
       <button class="gig-search-mobile-filters__toggle">
       </button>
      </div>
     <div class="gig-search-mobile-filters__modal" v-if="open">
       <div class="gig-search-mobile-filters__modal-header">
         <i @click="closeModal" class="gig-search-mobile-filters__close-modal yellow-arrow-left-icon" />
         <button @click="clearFilters" class="gig-search-mobile-filters__clear">Tyhjennä valinnat</button>
       </div>

        <div>
          <div class="gig-search-mobile-filters__city-filter-container">
            <h4 class="gig-search-mobile-filters__modal-filter-heading">Kaupunki</h4>

            <div class="gig-search-mobile-filters__filter-values">
              <div class="gig-search-mobile-filters__filter-value"
                @click="clearCities"
                v-bind:class="{'active': selectedCities.length === 0}"
              >
                Koko suomi
              </div>
              <div class="gig-search-mobile-filters__filter-value"
                v-for="city in cities"
                :key="city.id"
                @click="selectCity(city)"
                v-bind:class="{'active': cityIsActive(city)}"
              >
                {{city.name}}
              </div>
            </div>
          </div>

          <h4 class="gig-search-mobile-filters__modal-filter-heading">Ajankohta</h4>
          <div class="gig-search__predefined-dates">
            <a @click="selectToday" v-bind:class="{'active': isTodaySelected()}">Tänään</a>
            <a @click="selectTomorrow" v-bind:class="{'active': isTomorrowSelected()}">Huomenna</a>
            <a @click="selectWeekend" v-bind:class="{'active': isWeekendSelected()}">Viikonloppu</a>
            <a @click="selectNextWeek" v-bind:class="{'active': isNextWeekSelected()}">Ensi viikolla</a>
          </div>

          <div class="gig-search-mobile-filters__genre-filter-container" v-if="false">
            <h4 class="gig-search-mobile-filters__modal-filter-heading">Genre</h4>
            <div class="gig-search-mobile-filters__filter-values">
              <div class="gig-search-mobile-filters__filter-value"
                @click="clearGenres"
                v-bind:class="{'active': selectedGenres.length === 0}"
              >
                Kaikki genret
              </div>

              <div class="gig-search-mobile-filters__filter-value"
                v-for="genre in genres"
                :key="genre.id"
                @click="selectGenre(genre)"
                v-bind:class="{'active': genreIsActive(genre)}"
              >
                {{genre.name}}
              </div>
            </div>
          </div>
        </div>

        <div class="gig-search-mobile-filters__modal-footer">
          <div class="gig-search-mobile-filters__show-gigs-button-container">
            <button class="gig-search-mobile-filters__show-gigs-button" @click="showGigs">Näytä keikat</button>
          </div>
        </div>
     </div>
   </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import {updateUrlParams} from '../packs/application';

export default {
  data: function () {
    return {
      open: false,
      selectedCities: [],
      selectedGenres: [],
      selectedDate: this.defaultSelectedDate,
      selectedDateTo: this.defaultSelectedDateTo
    }
  },
  computed: {
  },
  methods: {
     closeModal() {
        this.open = false;
        this.$emit('filtersChanged', {cities: this.selectedCities, genres: this.selectedGenres, selectedDate: this.selectedDate, selectedDateTo: this.selectedDateTo})
     },

     openModal() {
       this.open = true;
     },

     clearFilters() {
       this.selectedCities = [];
       this.selectedGenres = [];
     },

     // cities
     selectCity(city) {
       if (!this.selectedCities.includes(city)) {
        this.selectedCities = []; // TOOO: temporary allow only single city
        this.selectedCities.push(city);
       } else {
         // remove city
         this.selectedCities = this.selectedCities.filter(function(item) {
            return item.id !== city.id
         })
       }
       updateUrlParams("city", city.id);
     },

     cityIsActive(city) {
       if (!!this.selectedCities.find(c => c.id === city.id)) return true;
       return false;
     },

     clearCities() {
       this.selectedCities = [];
       updateUrlParams("city", undefined);
     },

     // genres
     selectGenre(genre) {
       if (!this.selectedGenres.includes(genre)) {
         this.selectedGenres = []; // TOOO: temporary allow only single genre
         this.selectedGenres.push(genre);
       } else {
         // remove genre
         this.selectedGenres = this.selectedGenres.filter(function(item) {
            return item.id !== genre.id
         })
       }
     },

     genreIsActive(genre) {
       if (this.selectedGenres.includes(genre)) return true;
       return false;
     },

     clearGenres() {
       this.selectedGenres = [];
     },

     showGigs() {
       this.open = false;
       this.$emit('filtersChanged', {cities: this.selectedCities, genres: this.selectedGenres, selectedDate: this.selectedDate, selectedDateTo: this.selectedDateTo})
     },

     // Dates
     selectToday() {
      this.selectedDate = this.dateToday();
      this.selectedDateTo = undefined;
      updateUrlParams('date', 'today');
     },

     selectTomorrow() {
      this.selectedDate = this.dateTomorrow();
      this.selectedDateTo = undefined;
      updateUrlParams('date', 'tomorrow');
     },

     selectWeekend() {
      this.selectedDate = this.dateStartOfFriday();
      this.selectedDateTo = this.dateEndOfSunday();
      updateUrlParams('date', 'weekend');
     },

     selectNextWeek() {
      this.selectedDate = this.dateNextWeekMonday();
      this.selectedDateTo = this.dateNextWeekSunday();
      updateUrlParams('date', 'nextweek');
     },

     dateToday() {
      const now = moment()
      return now.startOf('day').toDate();
     },

     dateTomorrow() {
       return moment().add(1, 'days').startOf('day').toDate();
     },

     dateNextWeekMonday() {
      const dayINeed = 1; // for Thursday
      const today = moment().isoWeekday();

      // if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) { 
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed).toDate();
      } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, 'weeks').isoWeekday(dayINeed).toDate();
      }
     },

     dateNextWeekSunday() {
      return moment(this.dateNextWeekMonday()).add(6,'days').toDate();
     },

     dateStartOfFriday() {
      return moment().isoWeekday(5).startOf('day').toDate();
     },

     dateEndOfSunday() {
      return moment().isoWeekday(7).endOf('day').toDate();
     },

     isTodaySelected() {
      if (!this.selectedDate) return false;
      return moment(this.selectedDate).isSame(moment(this.dateToday()));
     },

     isTomorrowSelected() {
      return moment(this.selectedDate).isSame(moment(this.dateTomorrow()));
     },

     isWeekendSelected() {
      return moment(this.selectedDate).isSame(moment(this.dateStartOfFriday())) && 
        moment(this.selectedDateTo).isSame(moment(this.dateEndOfSunday()));
     },

     isNextWeekSelected() {
      return moment(this.selectedDate).isSame(moment(this.dateNextWeekMonday()), 'day') && 
        moment(this.selectedDateTo).isSame(moment(this.dateNextWeekSunday()), 'day');
     },
  },
  props: ['selectedGenre', 'selectedCity', 'defaultSelectedDate', 'defaultSelectedDateTo', 'cities', 'genres'],
  watch: {
    defaultSelectedDate: function(newVal, oldVal) {
      this.selectedDate = newVal;
    },
    defaultSelectedDateTo: function(newVal, oldVal) {
      this.selectedDateTo = newVal;
    },
    selectedCity: function(newVal, oldVal) {
      this.selectedCities = [];
      this.selectedCities.push(newVal);
    }
  }
}
</script>
