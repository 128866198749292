<template>
   <div class="gigs-list">
     <div class="gigs-list__gigs">
       <gig class="gigs-list__gig" v-for="gig in visibleGigs" :gig="gig" :key="gig.id"></gig>
     </div>

     <p v-if="this.gigs.length === 0">Emme löytäneet tällä kertaa tulevia keikkoja.</p>

     <div class="gigs-list__button-container">
        <button class="gigs-list__load-more" @click="loadMore" v-if="hasMoreGigs()">Näytä lisää keikkoja</button>
     </div>
   </div>
</template>

<script>
const gigsVisibleInBegin = 9;
const showMoreGigsPerClick = 9;

import Gig from './Gig.vue';
export default {
  data: function () {
    return {
      visibleGigsCount: 9
    }
  },
  components: {
     Gig
  },
  computed: {
     visibleGigs: function() {
        return this.gigs.slice(0, this.visibleGigsCount);
     }
  },
  methods: {
     loadMore: function () {
        this.visibleGigsCount += 9;
     },

     hasMoreGigs: function () {
        return this.gigs.length > this.visibleGigsCount;
     }
  },
  props: ['gigs']
}
</script>
