<template>
  <div class="people-who-like">
    <ul>
      <li class="people-who-like__list-item" v-for="peopleWhoLike in likes" :key="peopleWhoLike.id">
        <div class="people-who-like__image-container">
          <img class="people-who-like__image" v-bind:src="peopleWhoLike.profile_image" heigth="50" />
        </div>
        <div class="people-who-like__name">
          {{peopleWhoLike.first_name}}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  data: function () {
    return {
    }
  },
  mounted: function () {
    const self = this;
    axios.get('/likes?id='+ this.resourceId + '&resource=' + self.resource)
    .then(function (response) {
      self.$store.commit('setLikes', response.data.likes)
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  computed: {
    likes: function() {
      return this.$store.state.likes
    }
  },
  methods: {
  },
  props: ['resourceId', 'resource']
}
</script>
