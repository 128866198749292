/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


//console.log('Hello World from Webpacker')
// Support component names relative to this directory:
/*var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)*/


// app/javascript/packs/application.js


import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

import App from '../app.vue'
import Gigs from '../components/Gigs.vue'
import LikeButton from '../components/LikeButton.vue'
import Likes from '../components/Likes.vue';
import PeopleWhoLike from '../components/PeopleWhoLike.vue';
import ShareGig from '../components/ShareGig.vue';
import GigSearch from '../components/GigSearch.vue';
import Comments from '../components/Comments.vue';
import MobileNavigation from '../components/MobileNavigation.vue';
import CookieModal from '../components/CookieModal.vue';
import moment from 'moment';
import 'es6-promise/auto';
import VueClipboard from 'vue-clipboard2';
//import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import Datepicker from 'vuejs-datepicker';
import { fi } from 'vuejs-datepicker/dist/locale';
//import axios from 'axios';

moment.locale('fi');
Vue.use(Vuex)
Vue.use(VueClipboard)
//Vue.use(AirbnbStyleDatepicker, {})

Vue.component('datepicker', Datepicker);
Vue.component('gigs', Gigs);
Vue.component('like-button', LikeButton);
Vue.component('likes', Likes);
Vue.component('people-who-like', PeopleWhoLike);
Vue.component('share-gig', ShareGig);
Vue.component('comments', Comments);
Vue.component('mobile-navigation', MobileNavigation);
Vue.component('gig-search', GigSearch);
Vue.component('cookie-modal', CookieModal);

Vue.filter('formatGigDate', function(value) {
  if (value) {
    return moment(String(value)).format('dd DD.MM | HH:mm')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
});

Vue.filter('formatDay', function(value) {
  if (value) {
    return moment(String(value)).format('dd DD.MM')
  }
});

Vue.use(TurbolinksAdapter)

const store = new Vuex.Store({
  state: {
    likeCount: 0,
    likes: [],
    liked: false,
    comments: []
  },
  mutations: {
    setLikeCount(state, n) {
      state.likeCount = n;
    },
    setLikes(state, n) {
      state.likes = n;
    },
    setLiked(state, n) {
      state.liked = n;
    }
  },
  getters: {
    likeCount: state => {
      return state.likeCount
    },
    getLikes: state => {
      return state.likes
    },
    liked: state => {
      return state.liked
    },
    comments: state => {
      return state.comments
    }
  }
})

export function updateUrlParams(param, value) {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  if (!value) {
    params.delete(param);
  } else {
    params.set(param, value);
  }
  let newUrl = window.location.pathname + '?' + params.toString();
  history.replaceState({ turbolinks: true, url: newUrl }, '', newUrl);
}

document.addEventListener('turbolinks:load', () => {
   const app = new Vue({
      el: '#vue-app',
      store,
   })
})
